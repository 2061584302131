@use 'sass:math';
$dWidth: 375;
@function rpx($d) {
  @return math.div($d, $dWidth) * 100 * 1vw;
}

// @font-face {
//   font-family: 'pingfang';
//   src: url('../public/font/pingfangjian.ttf');
//   font-display: swap;
// }

$color-white: white; // 常用
$color-gray: #f4f3f8; // 页面背景灰色
$border-color: #e7e7e7; // 边框颜色
$color-primary: #6571ff; // 主题色
$color-9: #999999; // 常用灰色文字
$color-6: #666666; // 常用二级文字
$color-3: #333333; // 常用正常文字
$color-blue: #3491fa; // 蓝色 常用于特殊状态中的普通颜色
$color-red: #ff5733; // 红色 常用于错误或严重警告
$color-green: #52c41a; // 绿色 常用于成功 或 可行
$color-origin: #faad14; // 橙色 常用于警告
$color-cyan: #7be6ff; // 青色 备用

// 字号
$fss: 8, 10, 12, 14, 16, 18, 20, 22, 24, 26;
// 底部导航栏高度
$bottom-nav-bar-height: rpx(44);

@each $fs in $fss {
  .font-size-#{$fs} {
    font-size: rpx($fs);
  }
  .line-height-#{$fs} {
    line-height: rpx($fs);
  }
}

body {
  // font-family: pingfang;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: rpx(12);
  overflow: hidden;
  color: $color-3;
  --color-primary: #6571ff;
  --color-primary-t0: #6571ff00;
  --color-primary-t1: #6571ff1a;
  --color-primary-t2: #6571ff33;
  --color-primary-t3: #6571ff4d;
  --color-primary-t4: #6571ff66;
  --color-primary-t5: #6571ff80;
  --color-primary-t6: #6571ff99;
  --color-primary-t7: #6571ffb3;
  --color-primary-t8: #6571ffcc;
  --color-primary-t9: #6571ffe6;
  $color-primary: var(--color-primary);
  $color-primary-t0: var(--color-primary-t0);
  $color-primary-t1: var(--color-primary-t1);
  $color-primary-t2: var(--color-primary-t2);
  $color-primary-t3: var(--color-primary-t3);
  $color-primary-t4: var(--color-primary-t4);
  $color-primary-t5: var(--color-primary-t5);
  $color-primary-t6: var(--color-primary-t6);
  $color-primary-t7: var(--color-primary-t7);
  $color-primary-t8: var(--color-primary-t8);
  $color-primary-t9: var(--color-primary-t9);

  $cos: 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1;
  $cs: (
    'white': $color-white,
    'gray': $color-gray,
    '9': $color-9,
    '6': $color-6,
    '3': $color-3,
    'blue': $color-blue,
    'red': $color-red,
    'green': $color-green,
    'origin': $color-origin,
    'cyan': $color-cyan,
  );
  @each $c, $v in $cs {
    .text-color-#{$c} {
      color: $v;
    }
    .bg-color-#{$c} {
      background-color: $v;
    }
    .border-color-#{$c} {
      border-color: $v;
    }
    @each $o in $cos {
      .text-color-#{$c}-t#{$o*10} {
        color: rgba($color: $v, $alpha: $o);
      }
      .bg-color-#{$c}-t#{$o*10} {
        background-color: rgba($color: $v, $alpha: $o);
      }
      .border-color-#{$c}-t#{$o*10} {
        border-color: rgba($color: $v, $alpha: $o);
      }
    }
    * {
      -webkit-tap-highlight-color: transparent;
    }
  }

  $csp: (
    'primary': $color-primary,
    'primary-t0': $color-primary-t0,
    'primary-t1': $color-primary-t1,
    'primary-t2': $color-primary-t2,
    'primary-t3': $color-primary-t3,
    'primary-t4': $color-primary-t4,
    'primary-t5': $color-primary-t5,
    'primary-t6': $color-primary-t6,
    'primary-t7': $color-primary-t7,
    'primary-t8': $color-primary-t8,
    'primary-t9': $color-primary-t9,
  );

  @each $c, $v in $csp {
    .text-color-#{$c} {
      color: $v;
    }
    .bg-color-#{$c} {
      background-color: $v;
    }
    .border-color-#{$c} {
      border-color: $v;
    }
    .gradient-asc-#{$c} {
      background-image: linear-gradient($v, $color-primary);
    }
    .gradient-desc-#{$c} {
      background-image: linear-gradient($color-primary, $v);
    }
  }
  .bg-color-t0 {
    background-color: transparent;
  }

  input,
  textarea {
    border: none !important;
    width: 1px;
    font-size: rpx(12);
    background-color: transparent;
    &:focus {
      outline: none;
    }
    &:disabled {
      background-color: transparent;
      color: $color-6;
    }
  }
}

.vw-100 {
  width: 100vw;
}
.vh-100 {
  height: 100vh;
}

.border-none {
  border: none !important;
}
.border {
  border: 1px solid $border-color;
}
.border-left {
  border-left: 1px solid $border-color;
}
.border-right {
  border-right: 1px solid $border-color;
}
.border-top {
  border-top: 1px solid $border-color;
}
.border-bottom {
  border-bottom: 1px solid $border-color;
}

$space: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 17, 18, 20, 22, 24, 26, 28, 30, 32, 34, 35, 36, 38, 40, 42, 44, 46,
  48, 50, 60, 63, 64, 66, 67, 68, 69, 70, 72, 80, 88, 90, 100, 110, 120, 126, 140, 144, 150, 180, 187, 190, 200, 220,
  240, 250, 255, 260, 275, 280, 295, 300, 325, 330, 335, 350, 355, 375, 400;
@each $sp in $space {
  .vw-#{$sp} {
    width: ($sp) * 1vw;
  }
  .vh-#{$sp} {
    height: ($sp) * 1vh;
  }
  .w-#{$sp} {
    width: rpx($sp);
  }
  .h-#{$sp} {
    height: rpx($sp);
  }
  .maxw-#{$sp} {
    max-width: rpx($sp);
  }
  .maxh-#{$sp} {
    max-height: rpx($sp);
  }
  .minw-#{$sp} {
    min-width: rpx($sp);
  }
  .minh-#{$sp} {
    min-height: rpx($sp);
  }
  .lh-#{$sp} {
    line-height: rpx($sp);
  }
  .l-#{$sp} {
    left: rpx($sp);
  }
  .r-#{$sp} {
    right: rpx($sp);
  }
  .t-#{$sp} {
    top: rpx($sp);
  }
  .b-#{$sp} {
    bottom: rpx($sp);
  }
  .bw-#{$sp} {
    width: ($sp) * 1%;
  }
  .bh-#{$sp} {
    height: ($sp) * 1%;
  }
  .m-#{$sp} {
    margin: rpx($sp);
    box-sizing: border-box;
  }
  .ml-#{$sp} {
    margin-left: rpx($sp);
    box-sizing: border-box;
  }
  .mr-#{$sp} {
    margin-right: rpx($sp);
    box-sizing: border-box;
  }
  .mt-#{$sp} {
    margin-top: rpx($sp);
    box-sizing: border-box;
  }
  .mb-#{$sp} {
    margin-bottom: rpx($sp);
    box-sizing: border-box;
  }
  .vml-#{$sp} {
    margin-left: $sp * 1vw;
    box-sizing: border-box;
  }
  .vmr-#{$sp} {
    margin-right: $sp * 1vw;
    box-sizing: border-box;
  }
  .vmt-#{$sp} {
    margin-top: $sp * 1vw;
    box-sizing: border-box;
  }
  .vmb-#{$sp} {
    margin-bottom: $sp * 1vw;
    box-sizing: border-box;
  }
  .mh-#{$sp} {
    box-sizing: border-box;
    margin-left: rpx($sp);
    margin-right: rpx($sp);
  }
  .mv-#{$sp} {
    box-sizing: border-box;
    margin-top: rpx($sp);
    margin-bottom: rpx($sp);
  }
  .p-#{$sp} {
    padding: rpx($sp);
    box-sizing: border-box;
  }
  .pl-#{$sp} {
    padding-left: rpx($sp);
    box-sizing: border-box;
  }
  .pr-#{$sp} {
    padding-right: rpx($sp);
    box-sizing: border-box;
  }
  .pt-#{$sp} {
    padding-top: rpx($sp);
    box-sizing: border-box;
  }
  .pb-#{$sp} {
    padding-bottom: rpx($sp);
    box-sizing: border-box;
  }
  .ph-#{$sp} {
    box-sizing: border-box;
    padding-left: rpx($sp);
    padding-right: rpx($sp);
  }
  .pv-#{$sp} {
    box-sizing: border-box;
    padding-top: rpx($sp);
    padding-bottom: rpx($sp);
  }
  .border-radius-#{$sp} {
    border-radius: rpx($sp);
  }
  .border-radius-tl-#{$sp} {
    border-top-left-radius: rpx($sp);
  }
  .border-radius-tr-#{$sp} {
    border-top-right-radius: rpx($sp);
  }
  .border-radius-bl-#{$sp} {
    border-bottom-left-radius: rpx($sp);
  }
  .border-radius-br-#{$sp} {
    border-bottom-right-radius: rpx($sp);
  }
  .z-#{$sp} {
    z-index: $sp;
  }
}

$op: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
@each $sp in $op {
  .opacity-#{$sp} {
    opacity: $sp * 0.1;
  }
}
.d-block {
  display: block;
}
.d-none {
  display: none;
}

.d-flex-r {
  display: flex;
  flex-direction: row;
}

.d-flex-c {
  display: flex;
  flex-direction: column;
}
.d-shadow {
  box-shadow: 0 rpx(1) rpx(8) rgba(0, 0, 0, 0.08);
}
.d-shadow-tl {
  box-shadow: rpx(-4) rpx(-4) rpx(8) rgba(0, 0, 0, 0.08);
}
.d-shadow-t {
  box-shadow: rpx(0) rpx(-4) rpx(8) rgba(0, 0, 0, 0.08);
}

$flxs: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

@each $fl in $flxs {
  .flex-#{$fl} {
    flex: $fl;
  }
}

$icons: 10, 12, 14, 16, 18, 20, 22, 24, 26, 36, 40, 48;
@each $ic in $icons {
  .icon-#{$ic} {
    width: rpx($ic);
    height: rpx($ic);
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.ai-inherit {
  align-items: inherit;
}
.ai-start {
  align-items: flex-start;
}

.ai-center {
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}

.ac-start {
  align-content: flex-start;
}
.ac-center {
  align-content: center;
}
.ac-end {
  align-content: flex-end;
}

.jc-start {
  justify-content: flex-start;
}

.jc-center {
  justify-content: center;
}

.jc-end {
  justify-content: flex-end;
}

.jc-between {
  justify-content: space-between;
}

.jc-around {
  justify-content: space-around;
}

.jc-evenly {
  justify-content: space-evenly;
}

.text-delete-line {
  text-decoration: line-through;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}
.overflow-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.omit-text {
  max-lines: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.word-wrap {
  word-wrap: break-word;
  white-space: break-spaces;
}
.word-nowrap {
  white-space: nowrap;
}
.cursor-pointer {
  cursor: pointer;
}
.font-widget-600 {
  font-weight: 600;
}
.font-widget-700 {
  font-weight: 700;
}
.font-widget-800 {
  font-weight: 800;
}

.pos-a {
  position: absolute;
}
.pos-r {
  position: relative;
}
.pos-f {
  position: fixed;
}

//---------------------------------------------------------------------
.home-card-info-tips {
  background: linear-gradient(to right, #c9d7e2, #d3dce8);
}
// 文字间距变小会显得挤 但能显未完
.text-short {
  letter-spacing: rpx(-0.5);
}
// 虚线边框
.border-left-dashed {
  border-left: 3px dashed $color-gray;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-animation {
  transform-origin: 0% 0%;
  animation: rotate 2s linear infinite; /* 抖动的持续时间 */
}

.check-bg {
  transform: translateX(-50%);
}
.check-state {
  transform: translateX(-50%) translateY(-50%);
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  7% {
    transform: translateX(-5px);
  }
  14% {
    transform: translateX(5px);
  }
  21% {
    transform: translateX(-5px);
  }
  28% {
    transform: translateX(5px);
  }
  35% {
    transform: translateX(-5px);
  }
  38% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

.shake-animation {
  animation: shake 4s; /* 抖动的持续时间 */
  animation-iteration-count: infinite; /* 动画重复次数 */
}

@keyframes cursorPointer {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fang-cursor-pointer {
  animation: cursorPointer 1s;
  animation-iteration-count: infinite;
}
