.realname-gride-dialog {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: rgba($color: #000000, $alpha: 0.5);
  .step-img-block {
    width: 80vw;
    height: 70vh;
    margin-left: 10vw;
    .slick-slider {
      height: 56vh;
    }
    .slider-img-block {
      text-align: center;
      .slider-img {
        height: 50vh;
        display: inline-block;
        width: auto;
      }
      .slider-index {
        box-sizing: border-box;
        width: 100%;
        text-align: center;
        height: rpx(40);
        line-height: rpx(40);
      }
    }
    .slick-prev {
      left: rpx(10);
      z-index: 999;
      &::before {
        color: $color-9;
      }
    }
    .slick-next {
      right: rpx(10);
      z-index: 999;
      &::before {
        color: $color-9;
      }
    }
  }
}
