.city-select-sheet {
  $color-primary: var(--color-primary);
  .select-block {
    border-bottom: rpx(1) solid $color-gray;

    .select-item {
      color: $color-3;
      box-sizing: border-box;
      margin-right: rpx(20);

      &.active {
        color: $color-primary;
      }
    }
  }

  .list-block {
    height: 40vh;
    overflow: scroll;
  }
}
