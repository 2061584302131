.tab-bar-block {
  $color-primary: var(--color-primary);
  height: rpx(36);
  display: flex;
  .tab-bar-item {
    flex: 1;
    text-align: center;
    &.active {
      .label {
        color: $color-primary;
      }
      .active-line {
        background-color: $color-primary;
      }
    }
    .label {
      height: rpx(32);
      line-height: rpx(32);
      color: $color-3;
    }
    .active-line {
      width: rpx(24);
      height: rpx(3);
      border-radius: rpx(3);
      margin-left: 50%;
      transform: translateX(-50%);
      color: white;
    }
  }
}

.tab-bar-block-white {
  $color-primary: var(--color-primary);
  display: flex;
  justify-content: space-evenly;
  .tab-bar-item {
    text-align: center;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .active-line {
      display: none;
    }
    .label {
      width: max-content;
      padding: rpx(5) rpx(10);
      color: white;
      border-radius: rpx(6);
    }
    &.active {
      .label {
        color: $color-primary;
        background-color: white;
      }
    }
  }
}
