.bottom-box {
  z-index: 999999;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .mask {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
  }

  .content {
    position: relative;
    z-index: 999;
    border-radius: rpx(10) rpx(10) 0 0;
  }
}

.confirm-box {
  z-index: 999999;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.4);
  .content {
    border-radius: rpx(5);
    background-color: white;
    padding-top: 0;
    width: rpx(250);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      text-align: center;
      word-wrap: break-word;
      margin-bottom: rpx(20);
      margin-left: rpx(20);
      margin-right: rpx(20);
      box-sizing: border-box;
      color: $color-9;
    }
  }
}

.toast-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-color: transparent;
  .toast {
    position: fixed;
    bottom: 50vh;
    box-sizing: border-box;
    padding: rpx(10);
    margin: rpx(10);
    font-size: rpx(14);
    background-color: rgba($color: #000000, $alpha: 0.8);
    border-radius: rpx(10);
    border: 1px solid #777;
    min-width: rpx(150);
    text-align: center;
    color: white;
    z-index: 9999999999;
  }
}

.loading-mask {
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.4);
  .loading-mask-center {
    width: rpx(100);
    height: rpx(100);
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: rpx(8);
    .loading-pic {
      width: rpx(40);
      height: rpx(40);

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      &.rotating {
        animation: rotate 1s linear infinite;
      }
    }
    .loading-tips {
      margin-top: rpx(14);
      font-size: rpx(12);
      color: white;
      text-align: center;
    }
  }
}

// 复制组件
.copy-to-clipboard {
  .text-area {
    width: 0;
    height: 0;
    position: fixed;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
}
