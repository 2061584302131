.up-triangle {
  width: rpx(20);
  height: rpx(20);
  background: white;
  position: absolute;
  bottom: rpx(-5);
  left: rpx(10);
  z-index: 0;
  box-shadow: 0 0 rpx(8) rgba(0, 0, 0, 0.1);
  transform: rotateZ(135deg);
}

.down-triangle {
  width: rpx(20);
  height: rpx(20);
  background: white;
  position: absolute;
  top: rpx(-5);
  left: rpx(10);
  z-index: 0;
  box-shadow: 0 0 rpx(8) rgba(0, 0, 0, 0.1);
  transform: rotateZ(135deg);
}
