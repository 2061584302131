.button {
  user-select: none;
  $color-primary: var(--color-primary);
  background-color: $color-primary;
  color: white;
  text-align: center;
  padding: 0 rpx(10);
  width: max-content;
  position: relative;
  box-sizing: border-box;
  &.primary {
    &:active {
      opacity: 0.8;
    }
    &.outline {
      background-color: transparent;
      border: 1px solid $color-primary;
      color: $color-primary;
    }
    &.disabled {
      opacity: 0.8;
    }
  }
  &.gray {
    background-color: $color-gray;
    color: $color-9;
    &:active {
      opacity: 0.8;
    }
    &.outline {
      background-color: transparent;
      border: 1px solid $color-gray;
      color: $color-gray;
    }
    &.disabled {
      opacity: 0.8;
    }
  }

  &.default {
    background-color: white;
    // border: 1px solid $color-9;
    color: $color-3;
    &:active {
      opacity: 0.8;
    }
    &.outline {
      background-color: transparent;
      border: 1px solid $color-gray;
      color: $color-3;
    }
    &.disabled {
      opacity: 0.8;
    }
  }
  &.danger {
    background-color: $color-red;
    &:active {
      opacity: 0.8;
    }
    &.outline {
      background-color: transparent;
      border: 1px solid $color-red;
      color: $color-red;
    }
    &.disabled {
      opacity: 0.8;
    }
  }
  &.green {
    background-color: $color-green;
    &:active {
      opacity: 0.8;
    }
    &.outline {
      background-color: transparent;
      border: 1px solid $color-green;
      color: $color-green;
    }
    &.disabled {
      opacity: 0.8;
    }
  }
  &.warning {
    background-color: $color-origin;
    &:active {
      opacity: 0.8;
    }
    &.outline {
      background-color: transparent;
      border: 1px solid $color-origin;
      color: $color-origin;
    }
    &.disabled {
      opacity: 0.8;
    }
  }
  &.lg {
    height: rpx(48);
    line-height: rpx(48);
    font-size: rpx(16);
    &.circle {
      border-radius: rpx(24);
    }
  }
  &.md {
    height: rpx(36);
    line-height: rpx(36);
    font-size: rpx(14);
    &.circle {
      border-radius: rpx(18);
    }
  }
  &.sm {
    height: rpx(28);
    line-height: rpx(28);
    font-size: rpx(12);
    &.circle {
      border-radius: rpx(14);
    }
  }
  &.xs {
    height: rpx(24);
    line-height: rpx(24);
    font-size: rpx(12);
    &.circle {
      border-radius: rpx(12);
    }
  }
  &.round {
    border-radius: rpx(4);
  }
  &.long {
    width: inherit;
  }
}
